import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import { Link } from 'gatsby'

const ScheduleLink = () => (
  <p>
    <Link to="/full-schedule">Learn More</Link>
  </p>
)

const BalletLink = () => (
  <p>
    <Link to="/full-schedule#ballet">Learn More</Link>
  </p>
)

const ModernLink = () => (
  <p>
    <Link to="/full-schedule#modern">Learn More</Link>
  </p>
)

const BoysLink = () => (
  <p>
    <Link to="/full-schedule#boys">Learn More</Link>
  </p>
)

const PointeLink = () => (
  <p>
    <Link to="/full-schedule#pointe">Learn More</Link>
  </p>
)

const TheatreLink = () => (
  <p>
    <Link to="/full-schedule#theatre">Learn More</Link>
  </p>
)

const HipHopLink = () => (
  <p>
    <Link to="/full-schedule#hiphop">Learn More</Link>
  </p>
)

const CompanyLink = () => (
  <p>
    <Link to="/full-schedule#company">Learn More</Link>
  </p>
)

const AdultLink = () => (
  <p>
    <Link to="/full-schedule#adults">Learn More</Link>
  </p>
)

const FitnessLink = () => (
  <p>
    <Link to="/full-schedule#fitness">Learn More</Link>
  </p>
)

const Schedule = (props) => (
  <Layout>
    <Helmet>
      <title>Schedule</title>
      <meta name="schedule" content="Fall 2024-Spring 2025 schedule" />
    </Helmet>

    <BannerLanding
      header="Class Schedule"
      subheader="Find The Class That's Right For You!"
    />

    <div id="MobileSchedule">
      <div class="tablehead">Monday</div>

      <div>
        Creative Movement
        <br />
        Babies and Toddlers
        <br />
        10:30-11am
        <ScheduleLink />
      </div>

      <div>
        Creative Movement
        <br />
        3-4 y/o
        <br />
        4-4:45pm
        <ScheduleLink/>
      </div>

      <div>
        Contemporary Modern 
        <br />
        Intermediate
        <br />
        4:45-5:45
        <ModernLink />
      </div>

      <div>
        Company 
        <br />
        Junior
       <br />
        5:45-6:15
        <CompanyLink />
      </div>

      <div>
        Contemporary Modern 
        <br />
        Advanced
        <br />
       6:25-7:30
        <ModernLink/>
      </div>

      <div>
        Hip Hop
        <br />
        Advanced
        <br />
        7:30-8:30pm
        <HipHopLink />
      </div>

      <div class="tablehead">Tuesday</div>

      <div>
        Contemporary Modern
        <br />
        Beginner
        <br />
        4:15-5:15
        <ModernLink />
      </div>

      <div>
        Ballet
        <br />
        Advanced
        <br />
        5:15-6:45
        <BalletLink />
      </div>

      <div>
        Principal Company        
        <br />
        Advanced       
        <br />
        6:45-7:25
        <CompanyLink />
      </div>

      <div>
      Ballet 
        <br />
      Adults
        <br />
        7:30-9:00pm
        <AdultLink />
      </div>

      <div class="tablehead">Wednesday</div>

      <div>
        Ballet
        <br />
        Upper Elementary School
        <br />
        4:30-5:30
        <BalletLink />
      </div>

      <div>
        Hip Hop
        <br />
        Beginner
        <br />
        5:30-6:15
        <HipHopLink />
      </div>

      <div>
       Hip Hop
        <br />
        Intermediate
        <br />
       6:15-7:15
        <HipHopLink />
      </div>

      <div>
        Ballet
        <br />
        Intermediate
        <br />
        6-7:00pm
        <BalletLink />
      </div>

              <div>
        Pointe Ballet
        <br />
        Advanced
        <br />
        7-8:30pm
        <PointeLink />
      </div>

      <div class="tablehead">Thursday</div>

      <div>
        Ballet       
        <br />
        Younger Elementary School      
        <br />
        4:15-5:15pm      
        <BalletLink />
      </div>

      <div>
        Partnering and Improvisation  
        <br />
      Intermediate
        <br />
        5:15-6:15
        <BalletLink />
      </div>

      <div>
        Contemporary and Horton
        <br />
        Advanced
        <br />
        6:15-7:15
        <ModernLink />
      </div>

      <div>
        Company Rehearsal <br />
        Advanced
        <br />
        7:15-8:00
        <CompanyLink />
      </div>

      <div>
        Fitness
        <br />
        For Adults <br />
       7:30-8:30
        <FitnessLink />
      </div>


      <div class="tablehead">Friday</div>

      <div>
        Ballet
        <br />
        Mid-Elementary School
        <br />
        4:30-5:30
        <BalletLink />
      </div>

      <div>
        Ballet/Pre-Pointe
        <br />
        Intermediate
        <br />
        5:30-7:00
        <PointeLink />
      </div>

          <div>
        Junior Company II
        <br />
        Intermediate
        <br />
        7:00-7:30
        <CompanyLink />
      </div>
    </div>

    <div id="Schedule">
      <div class="tablehead">Monday</div>

      <div class="tablehead">Tuesday</div>

      <div class="tablehead">Wednesday</div>

      <div class="tablehead">Thursday</div>

      <div class="tablehead">Friday</div>

      <div>
        <h5 class="weekday">Monday</h5>
        Creative Movement
        <br />
        Babies and Toddlers<br />
        10:30-11am <ScheduleLink />
      </div>

      <div>
        <h5 class="weekday">Tuesday</h5>
        Contemporary Modern        
        <br />
        Beginning<br />
        4:15-5:15 <ModernLink/>
      </div>

      <div>
        <h5 class="weekday">Wednesday</h5>
        Ballet
          <br />
        Upper Elementary School
        <br />
        4:30-5:30pm <BalletLink />
      </div>

      <div>
        <h5 class="weekday">Thursday</h5>
        Ballet <br />
       Younger Elementary School
        <br />
        4:15-5:15 <BalletLink />
      </div>

      <div>
        <h5 class="weekday">Friday</h5>
        Ballet <br />
        Mid-Elementary School
        <br />
        4:30-5:30 <BalletLink />{' '}
      </div>

      <div>
        <h5 class="weekday">Monday</h5>
        Creative Movement <br />
       3-4y/o<br />
        4-4:45pm
        <ScheduleLink />
      </div>

      <div>
        <h5 class="weekday">Tuesday</h5>
        Ballet
          <br />
        Advanced        
          <br />
        5:15-6:45
        <BalletLink />
      </div>

      <div>
        <h5 class="weekday">Wednesday</h5>
        Hip Hop<br />
        Beginning
        <br />
        5:30-6:15 <HipHopLink />
      </div>

      <div>
        <h5 class="weekday">Thursday</h5>
      Partnering and Improvisation
        <br />
        Intermediate
        <br />
        5:15-6:15
        <ModernLink />
      </div>

      <div>
        <h5 class="weekday">Friday</h5>
        Ballet/pre-pointe
        <br />
        Intermediate
        <br />
        5:30-7
        <BalletLink />
      </div>

      <div>
        <h5 class="weekday">Monday</h5>
        Contemporary<br />
       Intermediate <br />
       4:45-5:45
        <ModernLink />
      </div>

      <div>
        <h5 class="weekday">Tuesday</h5>
        Principal Rehearsal <br />
       Advanced Company <br />
        5:30-7:00
        <CompanyLink />
      </div>

      <div>
        <h5 class="weekday">Wednesday</h5>
        Hip Hop <br />
        Intermediate
        <br />
        6:15-7:15<HipHopLink />
      </div>

      <div>
        <h5 class="weekday">Thursday</h5>
        Contemporary & Hornton
        <br />
        Advanced
        <br />
        6:15-7:15
        <ModernLink />
      </div>

      <div>
        <h5 class="weekday">Friday</h5>
          Junior Company II
        <br />
        Company II
        <br />
        7-7:30pm
        <CompanyLink />
      </div>

      <div>
        <h5 class="weekday">Monday</h5>
        Junior Company I
        <br />
        Company I<br />
        5:45-6:15pm
        <CompanyLink />
      </div>

      <div>
        <h5 class="weekday">Tuesday</h5>
Ballet       <br />
        For Adults<br />
        7:30-9pm
        <AdultLink />
      </div>

      <div>
        <h5 class="weekday">Wednesday</h5>
        Ballet/Pointe
        <br />
        Advanced<br />
        7-8:30
        <PointeLink />
      </div>

      <div>
        <h5 class="weekday">Thursday</h5>
        Company Rehearsal
        <br />
        Full Company<br />
        7:15-8pm
        <CompanyLink />
      </div>

      <div>
        <h5 class="weekday">Friday</h5>
      </div>

      <div>
      <h5 class="weekday">Monday</h5>
        Contemporary
        <br />
        Advanced<br />
        6:25-7:30
        <ModernLink />
      </div>

      <div>
        <h5 class="weekday">Tuesday</h5>
      </div>

      <div>
        <h5 class="weekday">Wednesday</h5>
      </div>

      <div>
        <h5 class="weekday">Thursday</h5>
        Fitness
        <br />
        For Adults
        <br />
       7:30-8:30
        <FitnessLink />
      </div>

      <div>
        <h5 class="weekday">Friday</h5>
      </div>

      <div>
        <h5 class="weekday">Monday</h5>
        Hip Hop
        <br />
        Advanced
        <br />
       7:30-8:30
        <HipHopLink />
      </div>

      <div>
        <h5 class="weekday">Tuesday</h5>
      </div>

      <div>
        <h5 class="weekday">Wednesday</h5>
      </div>

      <div>
        <h5 class="weekday">Thursday</h5>
      </div>

      <div>
        <h5 class="weekday">Friday</h5>
      </div>

      
    </div>
  </Layout>
)

export default Schedule
